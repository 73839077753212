@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*>* {
    font-family: 'Lato', sans-serif;
}

html {
    overflow: hidden;
    overscroll-behavior: none;
}

body {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* height: -webkit-fill-available; */
    overflow-x: hidden;
    overflow-y: hidden;
    overscroll-behavior: none;
    touch-action: none;
    background: linear-gradient(102.87deg, rgba(86, 158, 194, 0.2) 28.76%, rgba(163, 196, 213, 0.2) 44.56%, rgba(230, 230, 230, 0.2) 54.38%, rgba(198, 176, 191, 0.2) 64.19%, rgba(159, 109, 144, 0.2) 81.09%), #FFFFFF;
    background-attachment: fixed;
}

#root {
    height: 100%;
}

.gradient-bg{
    background: linear-gradient(102.87deg, rgba(86, 158, 194, 0.4) 28.76%, rgba(163, 196, 213, 0.4) 44.56%, rgba(198, 176, 191, 0.4) 64.19%, rgba(159, 109, 144, 0.4) 81.09%), #FFFFFF;
}

.gradient-light-bg{
    background: linear-gradient(102.87deg, rgba(86, 158, 194, 0.2) 28.76%, rgba(163, 196, 213, 0.2) 44.56%, rgba(198, 176, 191, 0.2) 64.19%, rgba(159, 109, 144, 0.2) 81.09%), #FFFFFF;
}

.logo {
    font-family: 'Comfortaa', cursive;
}

.heading {
    background: -webkit-linear-gradient(135.27deg, #569EC2 5.8%, #9F6D90 99.72%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px transparent;
}

.user-link {
    background: -webkit-linear-gradient(135.27deg, #569EC2CC 5.8%, #9F6D90CC 99.72%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 0px transparent;
    font-weight: 700;
    position: relative;
}

.user-link:hover {
    background: -webkit-linear-gradient(135.27deg, #569EC2 5.8%, #9F6D90 99.72%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-shadow: 0px 0px transparent;
    -webkit-text-fill-color: transparent;
}

.user-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background: -webkit-linear-gradient(135.27deg, #569EC2 5.8%, #9F6D90 99.72%);
    transform: scaleX(0);
    transition: transform 0.3s ease-out;
}

.user-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}

/* .user-link-disabled, .user-link-disabled:hover {
    background: none;
    background-clip: unset;
    -webkit-background-clip: unset;
    color: gray;
    -webkit-text-fill-color: unset;
    font-weight: 500;
}

.user-link-disabled::after, .user-link-disabled:hover::after  {
    content: none;
} */

.grid-template-col-250 {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

@media (min-width: 640px) {
    .grid-template-col-expense {
        grid-template-columns: 2fr 1fr 1fr;
    }
}

@media (min-width: 768px) {

    /* Firefox */
    * {
        scrollbar-width: thin;
        scrollbar-color: #dde9f0, #9dc2d4
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        background: #dde9f0;
        border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #9dc2d4;
        border-radius: 14px;
        border: 2px solid #dde9f0;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: #79a6bd;
    }
}

.mobile-h-safe {
    height: calc(100% - 96px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.nav-pt-safe {
    padding-top: env(safe-area-inset-top);
}